import React, { useEffect } from 'react';
import cn from 'classnames';

import { ReactComponent as IconClose } from './close.svg';

import './Popup.scss';

const Popup = ({ children, onClose, className }) => {
  useEffect(() => {
    const body = document.querySelector('body');
    body.classList.add('--no-scroll');

    return () => {
      body.classList.remove('--no-scroll');
    };
  }, []);

  const handle = e => {
    if (e.target.classList.contains('popup')) {
      onClose();
    }
  };

  return (
    <div className={cn('popup', className)} onClick={handle}>
      <div className="popupContent">
        <div className="popupClose" onClick={onClose}><IconClose /></div>
        {children}
      </div>
    </div>
  )
};

export default Popup;
